<template>
  <b-modal
    id="modal-checkout"
    title="Pembayaran"
    hide-footer
    @hidden="modalCheckoutOnHide"
    @show="modalCheckoutOnShow"
  >
    <b-tabs
      align="center"
      class="mb-5"
    >
      <b-tab
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.id"
        :title="paymentMethod.name"
        :active="form.payment_status_id != 2 ? paymentMethod.id == 1 : paymentMethod.id == form.payment_method_id"
        @click="paymentMethodOnClick(paymentMethod.id)"
      >
      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-between align-items-center">
      <span style="width: 150px">Subtotal</span>
      <span class="font-weight-bold">{{ parseInt(form.total_amount).toLocaleString('id-ID') }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <span style="width: 150px">Total</span>
      <span class="font-weight-bold">{{ parseInt(form.final_amount).toLocaleString('id-ID') }}</span>
    </div>

    <template v-if="isHospitalTransaction">
      <hr style="margin: 5px">
    </template>

    <div
      class="d-flex justify-content-between align-items-center"
      v-if="isHospitalTransaction"
    >
      <span style="width: 150px">Bayar</span>
      <span class="font-weight-bold">{{ form.payment_amount == '' ? 0 : form.payment_amount.toLocaleString('id-ID') }}</span>
    </div>
    <template v-if="isHospitalTransaction">
      <div
        v-if="form.payment_status_id != 2 && !isEditPayment"
        class="d-flex justify-content-between align-items-center"
      >
        <span style="width: 150px">Sisa Tunggakan</span>
        <span class="font-weight-bold">{{ form.final_amount < 0 ? 0 : parseInt(form.final_amount).toLocaleString('id-ID') }}</span>
      </div>
      <div
        v-if="form.payment_status_id == 2 && isEditPayment"
        class="d-flex justify-content-between align-items-center"
      >
        <span style="width: 150px">Sisa Tunggakan</span>
        <span class="font-weight-bold">{{ form.count_final_amount < 0 ? 0 : parseInt(form.count_final_amount).toLocaleString('id-ID') }}</span>
      </div>
      <div
        v-if="form.payment_status_id == 2 && !isEditPayment"
        class="d-flex justify-content-between align-items-center"
      >
        <span style="width: 150px">Sisa Tunggakan</span>
        <span class="font-weight-bold">{{ form.count_unpaid_amount < 0 ? 0 : parseInt(form.count_unpaid_amount).toLocaleString('id-ID') }}</span>
      </div>
    </template>

    <!-- Cash -->
    <template v-if="form.payment_method_id == 1">
      <template v-if="!isHospitalTransaction">
        <hr style="margin: 5px">
        <div class="d-flex justify-content-between align-items-center">
          <span style="width: 150px">Bayar</span>
          <span class="font-weight-bold">{{ form.payment_amount == '' ? 0 : form.payment_amount.toLocaleString('id-ID') }}</span>
        </div>
        <template v-if="isHospitalTransaction">
          <div
            v-if="form.payment_status_id != 2"
            class="d-flex justify-content-between align-items-center"
          >
            <span style="width: 150px">Sisa Tunggakan</span>
            <span class="font-weight-bold">{{ form.unpaid_amount < 0 ? 0 : parseInt(form.unpaid_amount).toLocaleString('id-ID') }}</span>
          </div>
          <div
            v-if="form.payment_status_id == 2"
            class="d-flex justify-content-between align-items-center"
          >
            <span style="width: 150px">Sisa Tunggakan</span>
            <span class="font-weight-bold">{{ form.count_unpaid_amount < 0 ? 0 : parseInt(form.count_unpaid_amount).toLocaleString('id-ID') }}</span>
          </div>
        </template>
      </template>
      <div class="d-flex justify-content-between align-items-center">
        <span style="width: 150px">Kembalian</span>
        <span class="font-weight-bold">{{ parseInt(form.change_amount) < 0 ? 0 : parseInt(form.change_amount).toLocaleString('id-ID') }}</span>
      </div>
      <hr style="margin: 5px">
      <div class="row no-gutters mx-n1">
        <div class="col-6 p-1">
          <b-button
            class="btn btn-block btn-light text-success font-weight-bold"
            @click="btnShortCutOnClick(10000)"
            squared
          >10.000</b-button>
        </div>
        <div class="col-6 p-1">
          <b-button
            class="btn btn-block btn-light text-success font-weight-bold"
            @click="btnShortCutOnClick(20000)"
            squared
          >20.000</b-button>
        </div>
        <div class="col-6 p-1">
          <b-button
            class="btn btn-block btn-light text-success font-weight-bold"
            @click="btnShortCutOnClick(50000)"
            squared
          >50.000</b-button>
        </div>
        <div class="col-6 p-1">
          <b-button
            class="btn btn-block btn-light text-success font-weight-bold"
            @click="btnShortCutOnClick(100000)"
            squared
          >100.000</b-button>
        </div>
      </div>
      <hr style="margin: 5px">
      <div class="py-2">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              Rp
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            step='any'
            inputmode='numeric'
            v-model="formatedPaymentAmount"
            placeholder="Uang Cash"
            @keyup="inputCashOnKeyUp"
          ></b-form-input>
        </b-input-group>
        <small class="text-danger">{{ error.payment_amount }}</small>
      </div>
      <div class="py-2">
        <!-- Notes Input -->
        <b-form-group
          id="input-group-notes"
          label="Catatan:"
          label-for="input-notes"
        >
          <b-form-input
            id="input-notes"
            v-model="form.notes"
            placeholder="Catatan"
          ></b-form-input>
        </b-form-group>
      </div>
    </template>

    <!-- EDC -->
    <template v-if="form.payment_method_id == 2">

      <hr style="margin: 5px">
      <!-- Bank Input -->
      <b-form-group
        id="input-group-bank"
        label="Bank:"
        label-for="input-bank"
      >
        <treeselect
          v-model="form.bank_id"
          :multiple="false"
          :options="banks"
          placeholder="Pilih Bank"
        />
        <small class="text-danger">{{ error.bank_id }}</small>
      </b-form-group>

      <!-- Card Type Input -->
      <b-form-group
        id="input-group-card-type"
        label="Jenis Kartu:"
        label-for="input-card-type"
      >
        <b-form-select
          v-model="form.card_type"
          :options="cardTypes"
        ></b-form-select>
        <small class="text-danger">{{ error.card_type }}</small>
      </b-form-group>

      <!-- Card Number Input -->
      <b-form-group
        id="input-group-card-number"
        label="Nomor Kartu:"
        label-for="input-card-number"
      >
        <b-form-input
          id="input-card-number"
          v-model="form.card_number"
          placeholder="Nomor Kartu"
        ></b-form-input>
        <small class="text-danger">{{ error.card_number }}</small>
      </b-form-group>

      <!-- Card Number Input -->
      <b-form-group
        id="input-group-edc-struct"
        label="Struk EDC:"
        label-for="input-edc-struct"
      >
        <b-form-input
          id="input-edc-struct"
          v-model="form.edc_struct"
          placeholder="Struk EDC"
        ></b-form-input>
        <small class="text-danger">{{ error.edc_struct }}</small>
      </b-form-group>

      <b-form-group
        v-if="isHospitalTransaction"
        id="input-group-bank-transfer-amount"
        label="Nominal:"
        label-for="input-bank-transfer-amount"
      >
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              Rp
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            step='any'
            inputmode='numeric'
            v-model="formatedPaymentAmount"
            placeholder="Nominal"
            @keyup="inputCashOnKeyUp"
          ></b-form-input>
        </b-input-group>
        <small class="text-danger">{{ error.payment_amount }}</small>
      </b-form-group>

      <!-- Notes Input -->
      <b-form-group
        id="input-group-notes"
        label="Catatan:"
        label-for="input-notes"
      >
        <b-form-input
          id="input-notes"
          v-model="form.notes"
          placeholder="Catatan"
        ></b-form-input>
      </b-form-group>

    </template>

    <!-- Transfer -->
    <template v-if="form.payment_method_id == 3">

      <hr style="margin: 5px">
      <!-- Bank Account Target Input -->
      <b-form-group
        id="input-group-bank-account-target"
        label="Rekening Tujuan:"
        label-for="input-bank-account-target"
      >
        <treeselect
          v-model="form.bank_account_id"
          :multiple="false"
          :options="bankAccounts"
          placeholder="Pilih Rekening Tujuan"
        />
        <small class="text-danger">{{ error.bank_account_id }}</small>
      </b-form-group>

      <!-- Image -->
      <b-form-group id="input-group-image">
        <label for="input-image">Bukti Transfer: <em class="text-muted">opsional</em></label>
        <b-form-file
          v-model="form.image"
          placeholder="Upload Gambar"
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <small class="text-danger">{{ error.image }}</small>
      </b-form-group>

      <b-form-group
        v-if="isHospitalTransaction"
        id="input-group-bank-transfer-amount"
        label="Nominal:"
        label-for="input-bank-transfer-amount"
      >
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              Rp
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            step='any'
            inputmode='numeric'
            v-model="formatedPaymentAmount"
            placeholder="Nominal"
            @keyup="inputCashOnKeyUp"
          ></b-form-input>
        </b-input-group>
        <small class="text-danger">{{ error.payment_amount }}</small>
      </b-form-group>

      <!-- Notes Input -->
      <b-form-group
        id="input-group-notes"
        label="Catatan:"
        label-for="input-notes"
      >
        <b-form-input
          id="input-notes"
          v-model="form.notes"
          placeholder="Catatan"
        ></b-form-input>
      </b-form-group>

    </template>

    <!-- E-Wallet -->
    <template v-if="form.payment_method_id == 4">
      <div class="row no-gutters mx-n1 justify-content-center mb-2">
        <div
          class="col-md-3"
          v-for="(eWallet, index) in eWallets"
          :key="index"
        >
          <b-button
            :pressed="form.e_wallet_id == eWallet.id"
            variant="light"
            class="image-container"
            @click="btnEWalletOnClick(eWallet.id)"
          >
            <div style='height: 80px; width: 80px; position: relative'>
              <img
                class="image"
                :src="eWallet.image"
              >
            </div>
          </b-button>
        </div>
      </div>
      <div
        class="py-2"
        v-if="isHospitalTransaction"
      >
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              Rp
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            step='any'
            inputmode='numeric'
            v-model="formatedPaymentAmount"
            placeholder="Uang Cash"
            @keyup="inputCashOnKeyUp"
          ></b-form-input>
        </b-input-group>
        <small class="text-danger">{{ error.payment_amount }}</small>
      </div>
      <!-- Notes Input -->
      <b-form-group
        id="input-group-notes"
        label="Catatan:"
        label-for="input-notes"
      >
        <b-form-input
          id="input-notes"
          v-model="form.notes"
          placeholder="Catatan"
        ></b-form-input>
      </b-form-group>
    </template>

    <b-button
      class="btn-success btn-block mt-3"
      @click="confirmCheckout"
    >Bayar</b-button>
  </b-modal>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  props: {
    isHospitalTransaction: Boolean,
    isEditPayment: Boolean,
    form: Object,
    error: Object,
  },

  components: {
    Treeselect
  },

  data() {
    return {
      // Payment Methods
      paymentMethods: [],
      // E-Wallet
      eWallets: [],
      // Banks
      banks: [],
      // CardTypes
      cardTypes: [
        { text: 'Pilih Jenis Kartu', value: '', disabled: true },
        { text: 'Kartu Kredit', value: 1 },
        { text: 'Kartu Debit', value: 2 },
      ],
      // bankAccounts
      // bankAccounts: [
      //   { text: 'Pilih Rekening Tujuan', value: '', disabled: true },
      //   { text: 'BCA 6340225226 an. Yayasan Hayandra Peduli', value: 'BCA 6340225226 an. Yayasan Hayandra Peduli' },
      //   { text: 'Mandiri 1220012225226 an. Yayasan Hayandra Peduli', value: 'Mandiri 1220012225226 an. Yayasan Hayandra Peduli' },
      // ],
      bankAccounts: [],
      bankAccountNames: []
    }
  },

  computed: {
    formatedPaymentAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.payment_amount = newValue.toLocaleString('id-ID')
        } else {
          this.form.payment_amount = 0
        }
      },
      get() {
        return this.form.payment_amount
      }
    }
  },

  methods: {

    async getPaymentMethods() {
      this.paymentMethods = await module.list('pharmacy-payment-methods')
      if (this.isHospitalTransaction) {

      } else {
        this.paymentMethods.splice(2, 1)
      }
    },

    async getEWallets() {
      this.eWallets = await module.list('pharmacy-payment-methods/get-e-wallets')
    },

    async getBanks() {
      let response = await module.setTreeSelect('banks')
      // Success
      if (response.state == 'success') {
        // Set Option Select        
        this.banks = response.data
        this.banks.unshift({ label: "Pilih Bank", id: '', isDisabled: true })
      }
    },

    async getBankAccounts() {
      let response = await module.setTreeSelect('bank-accounts')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.bankAccounts = response.data
        this.bankAccounts.unshift({ label: "Pilih Rekening Bank", id: '', isDisabled: true })
        this.bankAccountNames = this.bankAccounts
      }
    },

    paymentMethodOnClick(id) {
      this.$emit('paymentMethodOnClick', id)
    },

    btnShortCutOnClick(amount) {
      this.$emit('btnShortCutOnClick', amount)
    },

    btnEWalletOnClick(id) {
      this.$emit('btnEWalletOnClick', id)
    },

    inputCashOnKeyUp(evt) {
      this.$emit('inputCashOnKeyUp', evt)
    },

    modalCheckoutOnHide() {
      this.$emit('modalCheckoutOnHide')
    },

    modalCheckoutOnShow() {
      this.$emit('modalCheckoutOnShow')
    },

    confirmCheckout() {
      this.$emit('confirmCheckout')
    }

  },

  watch: {
    'form.bank_account_id': function (newVal) {
      if (typeof newVal !== 'undefined') {
        this.form.bank_account_name = this.bankAccountNames.find(x => x.id == newVal).label
      } else if (typeof newVal === 'undefined') {
        this.form.bank_account_name = this.form.bank_account_name = ''
        this.form.bank_account_id = ''
      }
    }
  },

  mounted() {
    this.getPaymentMethods()
    this.getBankAccounts()
    this.getBanks()
    this.getEWallets()
  }

}
</script>

<style>
</style>
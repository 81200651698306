<template>
  <div>
    <Form
      ref="form"
      route="payments"
      :form="form"
      :error="error"
      :isEdit="false"
      :payment_categories_id="payment_categories_id"
      :medicines_id="medicines_id"
      :lab_procedures_id="lab_procedures_id"
      :doctors="doctors"
      :labProcedures="labProcedures"
      :display="display"
      :payment_slips="[]"
      @btnCheckoutOnClick="btnCheckoutOnClick"
    />

    <ModalPatient
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="modalPatientOnSelect"
      @submitedPatient="modalPatientOnSelect"
    />

    <ModalMedicine
      :selectedItem="form.medicines_id"
      :isHospitalTransaction="true"
      :checkbox="true"
      :isAbjad="true"
      @chosenMedicine="modalMedicineOnSelect"
    />

    <ModalService
      :selectedItem="form.payment_categories_id"
      :selectedBundle="form.payment_category_bundle_items_id"
      @btnItemBundleOnClick="setServiceBundleItem"
      @btnEditItemBundleOnClick="editServiceBundleItem"
      @btnSeeBundleDetailOnClick="seeServiceBundleItem"
      @checkboxItemOnChange="modalServiceOnSelect"
      @customSubmitedCategory="modalServiceOnSelect"
    />

    <ModalServiceBundleItem
      ref="modalServiceBundleItem"
      :selectedItem="form.payment_category_bundle_items_id"
      @modalOnSubmit="modalServiceBundleItemOnSubmit"
    />

    <ModalCheckout
      :isHospitalTransaction="true"
      :form="form"
      :error="error"
      @paymentMethodOnClick="paymentMethodOnClick"
      @btnShortCutOnClick="btnShortCutOnClick"
      @inputCashOnKeyUp="inputCashOnKeyUp"
      @btnEWalletOnClick="btnEWalletOnClick"
      @modalCheckoutOnHide="modalCheckoutOnHide"
      @modalCheckoutOnShow="modalCheckoutOnShow"
      @confirmCheckout="confirmCheckout"
    />
  </div>
</template>

<script>

// components
import Form from "@/component/payments/NewForm.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import ModalMedicine from "@/component/general/ModalMedicinePayment.vue";
import ModalService from "@/component/general/ModalPaymentCategory.vue";
import ModalServiceBundleItem from "@/component/general/ModalPaymentCategoryBundleItem.vue";
import ModalCheckout from "@/component/general/ModalCheckout.vue";

// modules
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import PaymentModule from "@/core/modules/PaymentModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import JwtService from "@/core/services/jwt.service";

export default {
  components: {
    Form,
    ModalPatient,
    ModalMedicine,
    ModalCheckout,
    ModalService,
    ModalServiceBundleItem,
  },

  data() {
    return {
      // display
      display: {
        patient_name: "",
        medicines_name_array: [],
        payment_categories_name_array: [],
      },
      // form
      form: {
        patient_id: this.$route.query.patient_id ?? "",
        doctor_id: this.$route.query.doctor_id ?? "",
        appointment_id: this.$route.query.appointment_id ?? "",
        payment_status_id: "",
        bank_id: "",
        bank_account_id: "",
        image: "",
        card_number: "",
        card_type: "",
        edc_struct: "",
        bank_account_number: "",
        bank_account_name: "",
        e_wallet_id: "",
        payment_method_id: 1,
        payment_categories_id: [],
        payment_category_bundle_items_id: [],
        medicines_id: [],
        lab_procedures_id: [],
        surcharge: 0,
        total_amount: 0,
        discount: 0,
        final_amount: 0,
        tax: 0,
        payment_amount: 0,
        change_amount: 0,
        unpaid_amount: 0,
        count_unpaid_amount: 0,
        notes: "",
        has_usage: 0,
        invoice_notes: "",
      },
      // error
      error: {
        patient_id: "",
        doctor_id: "",
        payment_categories_id: "",
        medicines_id: "",
        lab_procedures_id: "",
        surcharge: "",
        total_amount: "",
        discount: "",
        final_amount: "",
        tax: "",
        bank_id: "",
        bank_account_id: "",
        image: "",
        card_number: "",
        card_type: "",
        edc_struct: "",
        bank_account_number: "",
        bank_account_name: "",
      },
      // other
      payment_categories_id: [],
      medicines_id: [],
      lab_procedures_id: [],
      // master data
      doctors: [],
      labProcedures: [],
      // notification
      new_invoice: null,
      // current user
      currentUser: getUser(),
      // image
      image: "",
    };
  },

  methods: {
    async getDoctors() {
      this.doctors = await PaymentModule.getDoctors(this.form.patient_id);
    },

    async getLabProcedures() {
      this.labProcedures = await PaymentModule.getLabProcedures();
    },

    // modal patient @select
    modalPatientOnSelect(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = "";
        this.getDoctors();
      }
      this.$bvModal.hide("modal-patient");
    },

    // modal medicine @select
    modalMedicineOnSelect(value, e) {
      this.$refs.form.modalMedicineOnSelect(value, e);
    },

    // modal service @select
    modalServiceOnSelect(value, index, e) {
      this.$refs.form.modalServiceOnSelect(value);
    },

    // modal service set service bundle item
    setServiceBundleItem(value, index, isChecked) {
      if (!isChecked) {
        this.$bvModal.show("modal-payment-category-bundle-items");
        this.$refs.modalServiceBundleItem.getData(value);
      } else {
        this.$refs.form.modalServiceOnSelect(value);
        let bundleItems = this.form.payment_category_bundle_items_id.filter(
          (x) => x.parent_id == value.id
        );
        this.$refs.form.modalServiceSetServiceBundleItem(bundleItems, value);
      }
    },

    // modal service edit service bundle item
    editServiceBundleItem(value, index, isChecked) {
      this.$bvModal.show("modal-payment-category-bundle-items");
      this.$refs.modalServiceBundleItem.getData(value);
    },

    // modal service see bundle item
    seeServiceBundleItem(value, index) {
      this.$bvModal.show("modal-payment-category-bundle-items");
      this.$refs.modalServiceBundleItem.getData(value);
    },

    // modal service bundle item @submit
    modalServiceBundleItemOnSubmit(bundleItems, paymentCategory) {
      let find = this.form.payment_categories_id.find(
        (x) => x.id == paymentCategory.id
      );
      let edit = false;
      if (paymentCategory.is_bundle == 1) {
        if (typeof find === "undefined") {
          this.$refs.form.modalServiceOnSelect(paymentCategory);
        } else {
          edit = true;
        }
      } else {
        this.$refs.form.modalServiceOnSelect(paymentCategory);
      }
      this.$refs.form.modalServiceSetServiceBundleItem(
        bundleItems,
        paymentCategory,
        edit
      );
    },

    // modal checkout payment method @click
    paymentMethodOnClick(id) {
      this.form.payment_method_id = id;
    },

    // modal checkout btn e-wallet @click
    btnEWalletOnClick(id) {
      this.form.e_wallet_id = id;
    },

    // modal checkout btn cash shortcut @click
    btnShortCutOnClick(amount) {
      this.form.payment_amount = parseInt(amount).toLocaleString("id-ID");
      if (this.form.payment_status_id == 2) {
        this.form.change_amount =
          parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) -
          this.form.unpaid_amount;
        this.form.count_unpaid_amount =
          this.form.unpaid_amount -
          parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10);
      } else {
        this.form.change_amount =
          parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) -
          this.form.final_amount;
        this.form.unpaid_amount =
          this.form.final_amount -
          parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10);
      }
    },

    // modal checkout input cash @keyup
    inputCashOnKeyUp(evt) {
      if (
        (evt.keyCode >= 48 && evt.keyCode <= 57) ||
        (evt.keyCode >= 96 && evt.keyCode <= 105) ||
        evt.keyCode == 8
      ) {
        this.form.change_amount =
          parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) -
          this.form.final_amount;
        this.form.unpaid_amount =
          this.form.final_amount -
          parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10);
      } else {
        evt.preventDefault();
      }
    },

    // modal checkout @hide
    modalCheckoutOnHide() {
      this.error = validation.clearValidationError(this.error);
      this.form.payment_amount = 0;
      this.form.change_amount = 0;
    },

    // modal checkout @show
    modalCheckoutOnShow() {
      this.error = validation.clearValidationError(this.error);
      this.form.payment_amount = 0;
      this.form.change_amount = 0;
    },

    // btn checkout @click
    btnCheckoutOnClick() {
      this.$bvModal.show("modal-checkout");
    },

    // modal checkout @checkout
    async confirmCheckout() {
      if (this.form.payment_method_id != 1) {
        if (this.form.payment_amount != 0 && parseInt(this.form.payment_amount.replace(/\D/g, ""), 10) > this.form.final_amount) {
          Swal.fire("Gagal", "Nominal Pembayaran tidak boleh melebihi Total Tagihan", "warning")
          return
        }
      }
      if (this.form.final_amount > 0 && this.form.payment_amount == 0) {
        Swal.fire("Gagal", "Nominal pembayaran tidak boleh 0", "warning")
        return
      }
      let form = this.form;
      this.image = this.form.image

      if (this.form.payment_method_id == 3 && this.form.image != "") {
        form.image = ""
      }

      // if (this.form.payment_method_id == 3 && this.form.image != "") {
      //   form = new FormData();
      //   for (const [key, value] of Object.entries(this.form)) {
      //     if (key == 'payment_categories_id' || key == 'medicines_id' || key == 'lab_procedures_id') {
      //       console.log(value);
      //       form.append(key, JSON.stringify(value))
      //     } else {
      //     form.append(key, value);
      //     }
      //   }
      // }

      let response = await module.submit(form, `payments/checkout`);
      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
        return;
      }

      Swal.fire({
        title: response.success.title,
        text: response.success.message,
        icon: "success",
      });
      this.$root.$emit("UpdateNewPayment", "semoga jalan");
      this.getNewInvoice();
      this.$router.push(`/payment/detail/${response.success.payment.id}`);
    },

    // get prescription by id
    async getPrescriptionById() {
      let prescription = await module.get(
        `prescriptions/${this.$route.query.prescription_id}`
      );
      if (prescription) {
        this.display.patient_name = prescription.patient_name;
        this.form.patient_id = prescription.patient_id;
        this.form.doctor_id = prescription.doctor_id;
        // set medicines
        prescription.display_medicine.forEach(async (value, index) => {
          let find = await PaymentModule.getMedicineById(value.id);
          if (typeof find != "undefined") {
            // push to form medicines_id
            let quantity = 0;
            let arrFrequency = value.frequency.split("+");
            arrFrequency.forEach((ele) => {
              quantity += parseInt(ele);
            });
            quantity = quantity * value.days;
            this.display.medicines_name_array.push({
              id: value.id,
              name: value.name,
            });
            this.form.medicines_id.push({
              label: value.name,
              id: value.id,
              price: find.price,
              stock: find.stock,
              quantity: quantity,
            });
            // sum amount
            this.form.total_amount += parseInt(find.price * quantity);
            this.form.final_amount += parseInt(find.price * quantity);
          }
        });
      }
    },

    getNewInvoice() {
      ApiService.get("total-new-payments").then((response) => {
        this.new_invoice = response.data;
        JwtService.setNewInvoice(response.data);
      });
    },
  },

  watch: {
    "form.patient_id": function (newVal) {
      this.getDoctors();
    },
    "form.doctor_id": function (newVal) {
      if (typeof newVal === "undefined") this.form.doctor_id = "";
    },
  },

  async mounted() {
    // set breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sales", route: "" },
      { title: "Buat Invoice" },
    ]);
    // get data
    this.getDoctors();
    this.getLabProcedures();
    // set form data by query params
    if (this.$route.query != {}) {
      this.form.patient_id = this.$route.query.patient_id;
      this.form.patient_name = this.$route.query.patient_name;
      this.display.patient_name = this.$route.query.patient_name;
      if (typeof this.$route.query.prescription_id != "undefined") {
        this.getPrescriptionById();
      }
    }
  },

}
</script>

<style>
</style>
<template>
  <div>

    <b-modal
      id="modal-payment-category"
      size="xl"
      title="Pilih Layanan"
      hide-footer
      @hidden="modalOnHide">
      <!-- Tabs -->
      <b-tabs>
        <!-- choose existing payment category -->
        <b-tab
          title="Layanan Standar"
          active>
          <div class="mt-5">
            <Table
              ref="table"
              purpose="modal"
              :selectedItem="selectedItem"
              :selectedBundle="selectedBundle"
              @checkboxItemOnChange="checkboxItemOnChange"
              @btnItemBundleOnClick="btnItemBundleOnClick"
              @btnEditItemBundleOnClick="btnEditItemBundleOnClick"
              @btnSeeBundleDetailOnClick="btnSeeBundleDetailOnClick" />
          </div>
        </b-tab>
        <!-- insert custom payment category -->
        <b-tab title="Layanan Non Standar">
          <div class="mt-4">
            <b-form @submit.stop.prevent="customCategoryFormOnSubmit">
              <!-- input name -->
              <b-form-group
                id="input-group-name"
                label="Nama Biaya:"
                label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Nama Biaya Layanan"
                  required></b-form-input>
              </b-form-group>

              <!-- input price -->
              <b-form-group
                id="input-group-price"
                label="Harga:"
                label-for="input-price">
                <b-form-input
                  id="input-price"
                  inputmode="numeric"
                  @keyup="priceOnKeyup"
                  v-model="formatedPrice"
                  placeholder="Harga..."
                  required></b-form-input>
                <small class="text-danger">{{ error.price }}</small>
              </b-form-group>

              <!-- doctor commission -->
              <b-form-group
                id="input-group-doctor-commission"
                :label="`Komisi Dokter (${isPercent ? '%' : 'Rp'}):`"
                label-for="input-doctor-commission">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      squared
                      @click="btnIsPercentToggle"
                      class="bg-light-primary font-weight-bold text-primary">{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
                  </template>
                  <b-form-input
                    id="input-doctor-commission"
                    :readonly="formatedPrice == 0"
                    @keyup="doctorCommisionOnKeyUp"
                    v-model="formatedDoctorCommission"
                    placeholder="Komisi Dokter..."
                    required></b-form-input>
                </b-input-group>
                <small class="text-danger">{{ error.doctor_commission }}</small>
              </b-form-group>

              <!-- hospital income -->
              <b-form-group
                id="input-group-hospital-income"
                :label="`Pendapatan Rumah Sakit (${isPercent ? '%' : 'Rp'}):`"
                label-for="input-hospital-income">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      squared
                      @click="btnIsPercentToggle"
                      class="bg-light-primary font-weight-bold text-primary">{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
                  </template>
                  <b-form-input
                    id="input-hospital-income"
                    :readonly="formatedPrice == 0"
                    @keyup="hospitalIncomeOnKeyUp"
                    v-model="formatedHospitalIncome"
                    placeholder="Pendapatan Rumah Sakit..."
                    required></b-form-input>
                </b-input-group>
                <small class="text-danger">{{ error.hospital_income }}</small>
              </b-form-group>

              <!-- Button -->
              <b-button
                type="submit"
                variant="primary">Terapkan</b-button>
              <b-button
                class="ml-2"
                variant="default"
                @click="$bvModal.hide('modal-payment-category')">
                Batal
              </b-button>
            </b-form>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>

import Table from '@/component/masters/payment-categories/TablePayment.vue'
import validation from '@/core/modules/ValidationModule.js'

export default {

  props: {
    selectedItem: Array,
    selectedBundle: Array,
  },

  components: {
    Table
  },

  data() {
    return {
      // form
      form: {
        name: '',
        price: '',
        doctor_commission: '',
        hospital_income: '',
      },
      // error
      error: {
        name: '',
        price: '',
        doctor_commission: '',
        hospital_income: '',
      },
      // other
      totalPercentage: 100,
      isPercent: false
    }
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString('id-ID')
        } else {
          this.form.price = ''
        }
      },
      get() {
        return this.form.price
      }
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.doctor_commission = newValue.toLocaleString('id-ID')
        } else {
          this.form.doctor_commission = ''
        }
      },
      get() {
        return this.form.doctor_commission
      }
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.hospital_income = newValue.toLocaleString('id-ID')
        } else {
          this.form.hospital_income = ''
        }
      },
      get() {
        return this.form.hospital_income
      }
    }
  },

  methods: {

    modalOnHide() {
      this.totalPercentage = 100
      this.form = validation.clearForm(this.form)
    },

    btnIsPercentToggle() {
      this.error.doctor_commission = ''
      this.error.hospital_income = ''
      this.totalPercentage = 100
      this.form.doctor_commission = ''
      this.form.hospital_income = ''
      this.isPercent ? this.isPercent = false : this.isPercent = true
    },

    priceOnKeyup(evt) {
      if ((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105) || evt.keyCode == 8) {
        if (this.formatedPrice != 0) {
          if (this.isPercent) {
            this.totalPercentage = 100
            this.form.doctor_commission = 50
            this.form.hospital_income = 50
          } else {
            let share = parseInt(this.form.price.replace(/\D/g, ''), 10) / 2
            this.form.hospital_income = !isNaN(share) ? share.toLocaleString('id-ID') : ''
            this.form.doctor_commission = !isNaN(share) ? share.toLocaleString('id-ID') : ''
          }
        } else {
          this.form.hospital_income = 0
          this.form.doctor_commission = 0
        }
      }
    },

    doctorCommisionOnKeyUp(evt) {
      if ((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105) || evt.keyCode == 8) {
        if (this.formatedPrice != 0) {
          if (this.isPercent) {
            this.totalPercentage = 100
            this.totalPercentage -= this.form.doctor_commission
            this.form.hospital_income = this.totalPercentage
          } else {
            let share = parseInt(this.form.price.replace(/\D/g, ''), 10)
            share -= parseInt(this.form.doctor_commission.replace(/\D/g, ''), 10)
            this.form.hospital_income = !isNaN(share) ? share.toLocaleString('id-ID') : ''
          }
        }
      }
    },

    hospitalIncomeOnKeyUp(evt) {
      if ((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105) || evt.keyCode == 8) {
        if (this.formatedPrice != 0) {
          if (this.isPercent) {
            this.totalPercentage = 100
            this.totalPercentage -= this.form.hospital_income
            this.form.doctor_commission = this.totalPercentage
          } else {
            let share = parseInt(this.form.price.replace(/\D/g, ''), 10)
            share -= parseInt(this.form.hospital_income.replace(/\D/g, ''), 10)
            this.form.doctor_commission = !isNaN(share) ? share.toLocaleString('id-ID') : ''
          }
        }
      }
    },

    checkboxItemOnChange(value, index, e) {
      this.$emit('checkboxItemOnChange', value, index, e)
    },

    btnItemBundleOnClick(value, index, checked) {
      this.$emit('btnItemBundleOnClick', value, index, checked)
    },

    btnEditItemBundleOnClick(value, index, checked) {
      this.$emit('btnEditItemBundleOnClick', value, index, checked)
    },

    btnSeeBundleDetailOnClick(value, index) {
      this.$emit('btnSeeBundleDetailOnClick', value, index)
    },

    customCategoryFormOnSubmit() {
      let intPrice = parseInt(this.form.price.replace(/\D/g, ''), 10)
      if (!this.isPercent) {
        let intDoctorCommission = parseInt(this.form.doctor_commission.replace(/\D/g, ''), 10)
        let intHospitalIncome = parseInt(this.form.hospital_income.replace(/\D/g, ''), 10)
        if (intPrice < intDoctorCommission || intPrice < intHospitalIncome) {
          Swal.fire('Gagal', 'Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga', 'warning')
        } else {
          this.$emit('customSubmitedCategory', {
            label: this.form.name,
            id: 0,
            price: parseInt(this.form.price.replace(/\D/g, ''), 10),
            const_price: parseInt(this.form.price.replace(/\D/g, ''), 10),
            ref_id: 0,
            discount_percentage: 0,
            discount_amount: 0,
            discount_type: 0,
            doctor_commission: intDoctorCommission,
            hospital_income: intHospitalIncome,
            quantity: 1,
            custom_input: true
          })
          this.$bvModal.hide('modal-payment-category')
        }
      } else {
        if (this.form.doctor_commission > this.totalPercentage || this.form.hospital_income > this.totalPercentage) {
          Swal.fire('Gagal', 'Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi 100%', 'warning')
        } else {
          let price = parseInt(this.form.price.replace(/\D/g, ''), 10)
          let calculatedDoctorCommission = (price * this.form.doctor_commission / 100)
          let calculatedHospitalIncome = (price * this.form.hospital_income / 100)
          this.$emit('customSubmitedCategory', {
            label: this.form.name,
            id: 0,
            price: price,
            const_price: price,
            ref_id: 0,
            discount_percentage: 0,
            discount_amount: 0,
            discount_type: 0,
            doctor_commission: calculatedDoctorCommission,
            hospital_income: calculatedHospitalIncome,
            is_bundle: 0,
            is_deposit: 0,
            quantity: 1,
            custom_input: true
          })
          this.$bvModal.hide('modal-payment-category')
        }
      }
    },

  }

}
</script>

<style scoped>
.bg-light-primary:hover {
  background-color: #e1f0ffa6 !important;
}
</style>